<script setup lang="ts">
import { getTranslatedProperty } from '@shopware-pwa/helpers-next';
import { onClickOutside } from '@vueuse/core';
import type { Schemas } from '#shopware';
import FlyoutMenu from '~/components/layout/flyout/FlyoutMenu.vue';

defineProps<{
    menuWrapperHeight?: number;
}>();

const { navigationElements } = useNavigation();
const isOpen = ref(false);
const topNav = ref(null);
const route = useRoute();

const toggleMenu = () => {
    isOpen.value = !isOpen.value;
};

onClickOutside(topNav, () => {
    isOpen.value = false;
});

const isProductItem = (navItem: Schemas['Category']): boolean => {
    return navItem.extensions?.attributes?.categoryKey === 'Shop_B2B';
};

watch(
    () => route.fullPath,
    () => {
        isOpen.value = false;
    },
);
</script>

<template>
    <nav
        ref="topNav"
        class="hidden lg:flex items-center"
    >
        <template
            v-for="navItem in navigationElements"
            :key="navItem.id"
        >
            <template v-if="isProductItem(navItem)">
                <SharedButton
                    :color="isOpen ? 'brand-primary' : 'gray-100'"
                    :class="{ 'font-bold': isOpen }"
                    class="mr-5 min-w-[140px] !px-3"
                    @click="toggleMenu"
                    @keydown.esc="isOpen = false"
                >
                    <component
                        :is="isOpen ? 'SvgoXMark' : 'SvgoBars'"
                        class="h-4.5 w-4.5 mr-2"
                    />
                    {{ getTranslatedProperty(navItem, 'name') }}
                </SharedButton>
                <FlyoutMenu
                    :is-open="isOpen"
                    :nav-items="navItem.children"
                    :menu-wrapper-height="menuWrapperHeight"
                />
            </template>
            <LayoutCategory
                v-else
                :category="navItem"
                class="text-lg py-2 text-gray-500 hover:text-brand-primary px-3.5"
            />
        </template>
    </nav>
</template>
